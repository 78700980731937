import mockup from '../assets/mockup.png';
import playstore from '../assets/playstore.png';
import appstore from '../assets/appstore.png';
import { Link } from 'react-router-dom';

const Mockup = () => {
    return (
        <>
        <div className='mockup' style={{display:'flex',justifyContent:'center', alignItems:'center', 'flexDirection':'column', marginTop:'100px'}}>
        <img src={mockup} className='App-logo' alt='logo' />
          <div className='cont-plat'>
            <a href='https://play.google.com/store/apps/details?id=com.jugalista.com' target='_blank' rel='noreferrer'>
              <img src={playstore} className='plat-logo' alt='Google Play Store logo' />
            </a>
            <a href='https://apps.apple.com/co/app/jugalista/id6449441898' target='_blank' rel='noreferrer'>
              <img src={appstore} className='plat-logo' alt='Apple Store logo' />
            </a>
          </div>
      </div>
      <div style={{display:'flex',justifyContent:'center', alignItems:'center', 'flexDirection':'column', marginTop:'20px', marginBottom:'20px'}}>
<Link className='terminos' to={`legal`}>
              Términos y Condiciones
            </Link>
      </div>
              
        </>
    );
    }
export default Mockup;
