
import { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from 'styled-components';

import PdfViewer from './PdfViewer';
import { Button, Grid } from '@mui/material';


const CustomTabPanel = (props) =>  {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ReglamentoHome = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <ContainerStyled>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Fútbol" {...a11yProps(0)} />
                <Tab label="Futsal" {...a11yProps(1)} />
                <Tab label="Fútbol de Salón" {...a11yProps(2)} />
            </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <PdfViewer pdfDocumentName='futbol.pdf' />
                <Grid container>
                    <Grid item xs={12} style={{textAlign:'center'}}>
    <Button variant="contained" className='button' href={process.env.PUBLIC_URL + '/pdfs/futbol.pdf' } target='_blank' download>Descargar Reglamento</Button>
                    </Grid>
                </Grid>
                
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <PdfViewer pdfDocumentName='futsal.pdf' />
                <Grid container>
                    <Grid item xs={12} style={{textAlign:'center'}}>
    <Button variant="contained" className='button' href={process.env.PUBLIC_URL + '/pdfs/futsal.pdf' } target='_blank' download>Descargar Reglamento</Button>
                    </Grid>
                </Grid>
                
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <PdfViewer pdfDocumentName='futbol-de-salon.pdf' />
                <Grid container>
                    <Grid item xs={12} style={{textAlign:'center'}}>
     <Button variant="contained" className='button' href={process.env.PUBLIC_URL + '/pdfs/futbol-de-salon.pdf' } target='_blank' download>Descargar Reglamento</Button>
                    </Grid>
                </Grid>
               
            </CustomTabPanel>
      </ContainerStyled>
    )
    }

export default ReglamentoHome;


const ContainerStyled = styled.div`
    width: 100%;
    margin-top: 60px;
    padding: 40px;

    @media (max-width: 992px) {
        padding: 20px;
    }
`;