import { useState, useRef } from 'react';

import { usePdf } from '@mikecousins/react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


const PdfViewer = ({ pdfDocumentName }) => {

    const [page, setPage] = useState(1);
    const canvasRef= useRef(null);

    const { pdfDocument } = usePdf({
        file: process.env.PUBLIC_URL + '/pdfs/' + pdfDocumentName,
        page,
        canvasRef,
    });

    return (
        <div className='pdf-container'>
            {!pdfDocument && <span>Cargando...</span>}
            <canvas ref={canvasRef} style={{maxWidth: '100%', height: 'auto'}} />
            {Boolean(pdfDocument && pdfDocument.numPages) && (
                <nav className='nav-pager' style={{marginTop: '20px', width: canvasRef?.current?.clientWidth ? canvasRef.current.clientWidth : '100%' }}>
                <ul className="pager">
                    <li className="previous">
                    <button disabled={page === 1} onClick={() => setPage(page - 1)}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    </li>
                    <li className="next">
                    <button
                        disabled={page === pdfDocument.numPages}
                        onClick={() => setPage(page + 1)}
                    >
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                    </li>
                </ul>
                </nav>
            )}
        </div>
    )
}

export default PdfViewer;