import { useContext } from 'react';
import { Context } from '../../../Context';

//assets
import logo from '../../../assets/logo.png';
const Loader = () => {
  const { loading } = useContext(Context);
  return loading ? (
    <div className='loader'>
      <img src={logo} alt='loader' />
    </div>
  ) : null;
};

export default Loader;
