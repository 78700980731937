import NavbarHome from './NavbarHome';

//import TournamentsHome from './TournamentsHome';
import Mockup from './Mockup';

const Home = () => {

  return (
    <div className='Home'>
      <NavbarHome />
      {/*<TournamentsHome />*/}
      <Mockup />
    </div>
  );
};

export default Home;
