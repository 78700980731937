import { Link } from 'react-router-dom';

import logo from '../assets/logo.png';

import './Legal.css';

const Legal = () => {
  return (
    <div className='legal'>
      <img src={logo} className='logo' alt='logo' />
      <Link to={`/`} className='back-link'>
        [Volver]
      </Link>

      <h1>Términos y Condiciones</h1>
      <p>
        Los Términos y Condiciones a continuación definen los derechos y las obligaciones de las partes en el marco de
        la utilización de la Aplicación. Constituyen la base de la relación contractual entre el Usuario y JUGALISTA;
        además, definen las principales normas de utilización de la Aplicación. Se complementan con la Política de Uso
        de Datos a fin de guiar de forma más precisa al Usuario en lo que respecta al uso de la Aplicación. En caso de
        que un conflicto entre las distintas políticas provoque la falta de accesibilidad, inteligibilidad o
        previsibilidad de las normas, los presentes Términos y Condiciones prevalecerán sobre la Política de Uso de
        Datos.
      </p>

      <p>
        En caso de que cualquiera de las disposiciones de los presentes Términos y Condiciones se declare inválida o
        inexigible en virtud de una ley, un reglamento o de una de una resolución dictada por un órgano jurisdiccional
        competente, sólo dejará de tenerse en cuenta dicha disposición, mientras que el resto seguirá conservando su
        validez y vigencia, salvo si los presentes Términos y Condiciones se desvirtúan o si ello acarrea un
        desequilibrio significativo en las obligaciones a cargo de las partes.{' '}
      </p>

      <h2>1. Servicios y funciones</h2>

      <h3>1.1 Objeto de los Servicios</h3>

      <p>
        JUGALISTA es una aplicación móvil que permite al Usuario buscar partidos existentes para participar en ellos,
        así como organizar encuentros deportivos e invitar a otros Usuarios a participar de los mismos fomentando la
        competencia a través de las calificaciones de los Usuarios y el ascenso en categorías clasificatorias. El uso de
        la Aplicación se limita estrictamente a fines personales y de ocio.
      </p>

      <p>
        Cada Usuario puede establecer relaciones con otros Usuarios de conformidad con los presentes Términos y
        Condiciones de Servicio, la legislación vigente, la Política de Uso de Datos.
      </p>

      <p>
        Los Servicios que JUGALISTA provee no se asemejan, en ningún caso, a las actividades de entrenamiento,
        afiliación deportiva profesional o promoción deportiva profesional (mercado de fichajes); se limitan
        exclusivamente a establecer una plataforma que permite y facilita el relacionamiento de personas para organizar
        o participar en encuentros deportivos.
      </p>

      <h3>1.2 Servicios prestados a los Usuarios</h3>

      <p>
        Los Servicios de JUGALISTA están disponibles a través de la Aplicación de forma gratuita (excluidos los gastos
        de conexión y los servicios de telecomunicaciones) para el Usuario, quien se compromete a cumplir los presentes
        Términos y Condiciones. Dichos servicios incluyen, entre otros:
      </p>

      <ul>
        <li>Creación de perfil de jugador de acuerdo con la información suministrada</li>
        <li>Creación de encuentros deportivos </li>
        <li>Búsqueda de encuentros deportivos disponibles para participar</li>
        <li>Envío de invitaciones a otros Usuarios para participar en los encuentros</li>
        <li>Solicitud a otros usuarios para participar en encuentros creados</li>
        <li>Recibo y envío de calificaciones que retroalimentan el perfil del Usuario</li>
        <li>Creación de equipos</li>
        <li>Facilitar el contacto del organizador (Capitán) con los Usuarios invitados al encuentro y viceversa</li>
        <li>Ranking de jugadores de acuerdo al rendimiento y experiencia en la Aplicación</li>
        <li>
          Posibilidad de agregar a otros Usuarios como favoritos y facilitar la convocatoria en futuros encuentros
        </li>
        <li>Recepción de notificaciones para las situaciones habilitadas por el Usuario</li>
        <li>Acceso a perfiles de otros Usuarios (visualización de los datos públicos de otros Usuarios)</li>
      </ul>

      <h2>2. Registro</h2>

      <h3>2.1 Modalidades de descarga de la Aplicación</h3>

      <p>
        La Aplicación se puede descargar gratuitamente en la en la Play Store de Google, en los Smartphone Android que
        utilizan la versión 5 o posterior.{' '}
      </p>

      <h3>2.2 Derecho del Usuario a registrarse</h3>

      <p>
        Cuando se realice el registro a través de la Aplicación, JUGALISTA le atribuye al Usuario, a título personal,
        una Cuenta que incluye:
      </p>

      <ul>
        <li>Una tarjeta de jugador</li>
        <li>Opción de notificaciones</li>
        <li>Módulos: Ranking, Encuentros y Agenda</li>
      </ul>

      <p>Solo se permite una Cuenta por Usuario.</p>

      <h3>2.3 Obligaciones del Usuario al registrarse</h3>

      <p>
        El Usuario deberá ser mayor de 18 años y velará por que toda la información que comunique en el momento de su
        registro sea exacta y esté actualizada.{' '}
      </p>

      <p>
        El proceso de registro se efectuará a través de los siguientes medios alternativos. Se solicitarán datos
        adicionales para poder realizar la creación de la ficha del jugador
      </p>

      <ul>
        <li>
          A través de Google sign in: La Aplicación tomará los datos relevantes de su cuenta de Google, teniendo en
          cuenta que se debe continuar con el registro de información adicional
        </li>
        <li>
          A través del teléfono móvil: el Usuario será quien deba proporcionar sus datos personales tales como nombre,
          apellido, sexo, fecha de nacimiento, al igual que otros datos adicionales
        </li>
      </ul>

      <p>
        En el momento del registro, el Usuario acepta los presentes Términos y Condiciones y la Política de Uso de Datos
        y se compromete a cumplir con sus obligaciones.
      </p>

      <h2>3. Modificación de los Términos y Condiciones </h2>

      <h3>3.1 Actualización</h3>

      <p>
        JUGALISTA se reserva el derecho de modificar, en todo momento y de forma unilateral, los presentes Términos y
        Condiciones del modo que estime necesario, o de cesar los Servicios.{' '}
      </p>

      <p>
        El Usuario recibirá una notificación sobre la actualización a través de la Aplicación o mediante el envío de un
        mensaje de correo electrónico a la dirección que haya comunicado. Es responsabilidad del Usuario garantizar la
        validez de dicha dirección de correo electrónico.
      </p>

      <p>
        El Usuario deberá leer atentamente los Términos y Condiciones actualizados y tomar conciencia de las
        modificaciones introducidas por dicha actualización.
      </p>

      <p>
        El hecho de que el Usuario siga utilizando los Servicios de la Aplicación con posterioridad a la actualización
        de los Términos y Condiciones implica su aceptación incondicional de los cambios efectuados en estos.
      </p>

      <p>
        En caso de desacuerdo con las modificaciones efectuadas, el Usuario puede comunicarse con JUGALISTA y realizar
        los comentarios o sugerencias que puedan tener lugar; en caso de no llegar a un acuerdo, puede tomar la decisión
        de cesar el uso de los Servicios y eliminar su Cuenta.
      </p>

      <h3>3.2 Aplicación inmediata</h3>

      <p>Toda nueva versión de los Términos y Condiciones se aplicará de inmediato a los Usuarios.</p>

      <h2>4. Protección de datos personales</h2>

      <p>
        Para JUGALISTA, la protección de sus datos personales y el respeto de su privacidad es de fundamental
        importancia. Lo invitamos a que lea nuestra Política de Uso de Datos.
      </p>

      <h2>5. Obligaciones y responsabilidad de JUGALISTA</h2>

      <h3>5.1 Medios técnicos y seguridad</h3>

      <p>
        JUGALISTA está obligado a proporcionar la infraestructura y los medios técnicos necesarios para la prestación de
        un servicio de calidad a los Usuarios que posean un un smartphone con sistema operativo Android versión 4.1 o
        posterior.{' '}
      </p>

      <p>
        No podrá responsabilizarse a JUGALISTA por ningún fallo de funcionamiento del celular del Usuario ni por los
        problemas de compatibilidad que puedan existir entre la Aplicación y dicho celular.
      </p>

      <p>
        JUGALISTA no asumirá responsabilidad alguna de los fallos, las interrupciones o el funcionamiento incorrecto de
        los Servicios del proveedor de acceso a Internet del Usuario, ni de las posibles causas externas que puedan
        provocar la interrupción o el deterioro del acceso a los Servicios de JUGALISTA.
      </p>

      <p>
        El Usuario deberá asegurarse de poseer una cuenta de correo segura. No obstante la adopción de estas
        precauciones o como consecuencia de no haberlas adoptado, existe la posibilidad, por pequeña que sea, de que la
        Cuenta del Usuario sea objeto de fraude.{' '}
      </p>

      <p>
        JUGALISTA advierte también al Usuario de que es posible que reciba, en casos excepcionales, Mensajes no deseados
        o Mensajes supuestamente enviados por JUGALISTA. El Usuario deberá permanecer atento y vigilante a fin de no ser
        víctima de fraude y, en caso de serlo, deberá notificar a JUGALISTA mediante correo electrónico a las
        direcciones indicadas en el numeral 10 de los presentes Términos y Condiciones.
      </p>

      <h3>5.2 Conducta ilegal</h3>

      <p>
        JUGALISTA no asumirá responsabilidad alguna de los actos ilegales objeto de sanciones civiles o penales que
        hayan cometido los Usuarios, que pueden incluir, entre otros, los siguientes:
      </p>

      <ul>
        <li>Fraude</li>
        <li>Daño a la propiedad</li>
        <li>Lesiones personales</li>
        <li>Hurto o robo</li>
        <li>Actividad comercial o de carácter profesional</li>
        <li>Suplantación de identidad</li>
        <li>Vulneración de los derechos de propiedad de terceros</li>
        <li>Vulneración de los derechos de la personalidad, como el derecho a la imagen o a la intimidad</li>
      </ul>

      <h3>5.3 Sistema de denuncia y de moderación de contenidos</h3>

      <p>
        JUGALISTA no tendrá ninguna obligación legal de vigilar el contenido transmitido o almacenado por los Usuarios a
        través de la Aplicación (fotos de perfil, textos de biografía y calificaciones con texto); no obstante, en su
        condición de prestador de los Servicios se compromete a contar con un sistema de notificación para denunciar
        todo contenido ilegal, abusivo, perjudicial o fuera de las funciones o Servicios ofrecidos. En caso de detectar
        este tipo de desviaciones, el usuario podrá notificarlo por correo electrónico a{' '}
        <a href='mailto:hola@jugalista.com' target='_blank' rel='noreferrer'>
          hola@jugalista.com
        </a>
        , en caso de que después de la evaluación del reporte se encuentre que dicho contenido infringe las normas
        establecidas en estos Términos y Condiciones, JUGALISTA se comprometerá a retirar rápidamente dicho contenido.
      </p>

      <p>
        Al margen de este procedimiento de denuncia, los Usuarios tienen la posibilidad de bloquear a otro Usuario cuyo
        comportamiento no cumpla con las normas de buena conducta a fin de impedir toda comunicación con el mismo.
      </p>

      <p>
        En efecto, el procedimiento de denuncia de JUGALISTA no sustituye en modo alguno la interposición de una
        denuncia ni la incoación de un procedimiento judicial contra el Usuario autor del contenido controvertido
        denunciado.{' '}
      </p>

      <p>
        Todo uso indebido del sistema de denuncia (denuncia infundada y efectuada de mala fe) también se considerará
        como una violación de los presentes Términos y Condiciones y podrá conllevar la suspensión / cancelación de la
        Cuenta del Usuario responsable de dicho uso indebido.
      </p>

      <h3>5.4 Normas de prudencia</h3>

      <p>
        El Usuario es el único responsable de la verificación de la identidad de las personas con las que podría
        organizar un encuentro. JUGALISTA no asumirá responsabilidad alguna en caso de que se produzca algún incidente,
        del tipo que fuere, durante dicho encuentro, antes o después del mismo.
      </p>

      <h2>6. Código de conducta del Usuario</h2>

      <h3>6.1 Deber de veracidad y honestidad al registrarse</h3>

      <p>Para adquirir la condición de Usuario, es necesario cumplir con cada uno de los siguientes requisitos:</p>

      <ul>
        <li>Cumplir los requisitos de mayoría de edad (haber cumplido los 18 años).</li>
        <li>Garantizar la exactitud y veracidad de la información proporcionada</li>
        <li>
          Comprometerse a mantener actualizada periódicamente la información que se haya suministrado al acceder a su
          Cuenta por medio de la Aplicación.
        </li>
        <li>Haber leído los presentes Términos y Condiciones y haberlas aceptado al validar el proceso de registro.</li>
      </ul>

      <p>
        JUGALISTA no tiene la obligación legal de comprobar la identidad y la edad de los Usuarios, ni los medios
        técnicos necesarios para hacerlo. No obstante, en caso de existencia de dudas, de elementos sospechosos o de
        denuncias de terceros a través del mecanismo de “denuncia” que permitan presumir una suplantación de identidad o
        la mención de información falsa o errónea, JUGALISTA procederá a la correspondiente investigación de los hechos
        a la mayor brevedad posible y, de ser necesario, podrá rectificar los datos facilitados sin alterar el perfil
        del Usuario.{' '}
      </p>

      <p>
        Durante todo el tiempo que dure la investigación, JUGALISTA se reservará el derecho de suspender la Cuenta del
        Usuario bajo sospecha, así como su acceso a la Aplicación, y de realizar las comprobaciones necesarias, en
        especial al solicitarle a dicho Usuario una copia de algún documento de identidad oficial.{' '}
      </p>

      <p>
        En caso de que el Usuario no pueda proporcionar dicho justificante, o de comprobarse el incumplimiento de los
        presentes Términos y Condiciones, JUGALISTA podrá cancelar la Cuenta del Usuario investigado.
      </p>

      <p>
        El Usuario se compromete a ser titular de una única cuenta. De lo contrario, JUGALISTA podrá cancelar las
        cuentas creadas con posterioridad.
      </p>

      <h3>6.2 Deber de veracidad y honestidad al utilizar la Aplicación</h3>

      <p>Para conservar la condición de Usuario, deberá cumplir con cada uno de los siguientes requisitos:</p>

      <ul>
        <li>Garantizar la honestidad y la sinceridad de sus declaraciones.</li>
        <li>
          Publicar solo fotografías y/o información de perfil que permitan reconocer fácilmente al Usuario y al menos en
          primer lugar una Foto de perfil en la que se pueda reconocer su rostro claramente, estos dos criterios
          constituyen una condición esencial para el uso de los Servicios. En caso de publicar una fotografía de otra
          persona que no corresponda con el Usuario, JUGALISTA se reservará el derecho de eliminar, a su entera
          discreción, todo perfil que no cumpla con estas Condiciones.
        </li>
        <li>No publicar en Internet información extraída de la Aplicación y relativa a otros Usuarios.</li>
        <li>
          No publicar en la Aplicación contenidos y fotografías de carácter pornográfico o que atenten contra el pudor y
          la dignidad humana.
        </li>
        <li>Respetar las leyes relativas a la propiedad intelectual y a los derechos de la personalidad.</li>
        <li>
          No cometer delitos de difamación y, de modo más general, no realizar declaraciones ofensivas, en especial de
          carácter racial o discriminatorio.
        </li>
        <li>Respetar las leyes y los reglamentos vigentes.</li>
        <li>
          No realizar declaraciones que constituyan un acto de incitación a la violencia o al odio racial, ni que hagan
          apología del terrorismo, de crímenes de guerra o de crímenes de lesa humanidad.
        </li>
        <li>Respetar las normas de buena conducta y educación hacia el resto de los Usuarios.</li>
        <li>
          No perjudicar, en modo alguno, el funcionamiento normal de los Servicios ni las infraestructuras de la
          Aplicación.
        </li>
        <li>
          No modificar, copiar, ensamblar, descompilar o intentar utilizar los códigos fuente, algoritmos y los
          softwares de la Aplicación para fines distintos de los previstos en las presentes Condiciones generales, y en
          particular para fines de ingeniería inversa.
        </li>
        <li>No utilizar robots ni otras herramientas para contactar a otras personas.</li>
        <li>No utilizar la Aplicación para organizar servicios profesionales</li>
        <li>No contactar a otros Usuarios con el fin de promocionar productos o servicios de pago.</li>
        <li>
          No comunicar a otros Usuarios sus datos de conexión personales, en particular el nombre de usuario y la
          contraseña de su Cuenta.
        </li>
        <li>
          No publicitar ni promover de modo alguno el uso de cualquier otro servicio, perteneciente o no a la
          competencia, ni cometer delitos de competencia desleal.
        </li>
        <li>
          Asimismo, el Usuario se abstendrá de efectuar cualquier operación de copia, difusión o transferencia de datos
          técnicos relativos a la Aplicación JUGALISTA que haya podido interceptar en la red.
        </li>
      </ul>

      <h3>6.3 Uso personal</h3>

      <p>
        El Usuario se compromete a utilizar la Aplicación exclusivamente con fines personales. JUGALISTA no podrá
        explotarse con fines comerciales, promocionales, electorales ni de reclutamiento. Queda terminantemente
        prohibido el uso de la biografía para el envío de mensajes con fines publicitarios o comerciales, así como
        contenidos, indicaciones o enlaces de hipertexto que remitan a sitios web o soportes de terceras empresas.
      </p>

      <h3>6.4 Responsabilidad del Usuario</h3>

      <p>El Usuario es el único responsable de sus actos y de los datos que publique por medio de la Aplicación.</p>

      <p>
        En caso de que se reclame a JUGALISTA alguna responsabilidad derivada del incumplimiento de obligaciones por
        parte del Usuario, este liberará a JUGALISTA de responsabilidad ante cualquier condena dictada contra dicha
        empresa que se derive de la inobservancia de las leyes o de los presentes Términos y Condiciones por parte de
        dicho Usuario.
      </p>

      <h3>6.5 Fotografías</h3>

      <p>
        Queda expresamente prohibido publicar cualquier tipo de fotografía de carácter pornográfico o exhibicionista que
        atente contra el pudor y contra la moral en general. La publicación de este tipo de fotografías constituirá una
        falta grave que podrá dar lugar a la desactivación o cancelación de la Cuenta del Usuario, sin perjuicio de
        otras sanciones que puedan imponer los tribunales.
      </p>

      <h3>6.6 Encuentros deportivos</h3>

      <p>
        JUGALISTA advierte a aquellos Usuarios que deseen organizar encuentros deportivos de la necesidad de tomar las
        debidas precauciones. Los Usuarios serán íntegramente responsables de comprobar la identidad de las personas con
        las que concierten dichos encuentros. La organización de encuentros, así como la convocatoria de los jugadores,
        se realizará bajo la responsabilidad exclusiva de los Usuarios. JUGALISTA no asumirá responsabilidad alguna por
        los actos cometidos o los problemas ocasionados, independientemente de su naturaleza, por Usuarios actuales o
        antiguos Usuarios en el marco de situaciones derivadas del uso de la Aplicación.
      </p>

      <p>
        Con respecto a aquellas personas a las que el Usuario haya decidido convocar a un encuentro deportivo, se
        recomienda encarecidamente no revelar datos personales que no figuren en el perfil de la Aplicación, como la
        dirección, lugar de trabajo o datos sensibles. Por motivos de seguridad, JUGALISTA aconseja especialmente que
        los encuentros se organicen exclusivamente en canchas ubicadas en lugares públicos donde exista una gran
        afluencia de gente, o bien que se avise a una persona allegada sobre tales encuentros y no se faciliten los
        propios datos de contacto hasta que no haya transcurrido un plazo razonable.
      </p>

      <h3>6.7 Equipos celulares</h3>

      <p>
        El coste de los equipos celulares (tanto los equipos físicos como los programas informáticos) necesarios para
        acceder a los Servicios, así como el resto de los gastos conexos, incluidos los gastos relativos a los servicios
        de telecomunicaciones, correrán por cuenta del Usuario, quien será el único responsable del equipo físico
        utilizado en el marco del Servicio.
      </p>

      <h2>7. Comunicación con el Usuario</h2>

      <h3>7.1 Notificaciones Push</h3>

      <p>
        El Usuario podrá recibir notificaciones push a través de la Aplicación destinadas a avisarle de acontecimientos
        importantes, entre los que se incluyen los siguientes:
      </p>

      <ul>
        <li>La invitación a un encuentro deportivo</li>
        <li>La aceptación de invitación por parte del Usuario invitado</li>
        <li>El rechazo de invitación por parte del usuario invitado</li>
        <li>Recepción de calificación después de un encuentro</li>
        <li>La cancelación de un Usuario previamente confirmado</li>
      </ul>

      <p>El Usuario podrá configurar las preferencias relativas al envío de notificaciones push desde su cuenta.</p>

      <h3>7.2 Mensajes publicitarios y promocionales</h3>

      <p>
        JUGALISTA podrá difundir, en el marco de la ejecución del contrato y a través de la Aplicación, mensajes
        publicitarios y promocionales tanto de sus anunciantes como de cualquier otro tipo de socios.
      </p>

      <h2>8. Propiedad intelectual</h2>

      <h3>8.1 Derecho de marca</h3>

      <p>
        Queda estrictamente prohibido efectuar, sin contar con la correspondiente autorización previa y expresa por
        escrito de JUGALISTA, cualquier operación de reproducción, utilización o exhibición total o parcial, así como
        cualquier modificación o eliminación de la marca y los derechos que se derivan de ella como, por ejemplo, a
        título de ilustración y con carácter no exhaustivo, el eslogan y el logotipo propuestos por la Aplicación, al
        margen de los procedimientos, las modalidades, los soportes y los medios empleados para ello, pudiendo dar lugar
        el incumplimiento de dicha prohibición a las correspondientes acciones legales.
      </p>

      <h3>8.2 Derechos de autor</h3>

      <p>
        Queda estrictamente prohibido efectuar, sin contar con la correspondiente autorización previa y expresa por
        escrito de JUGALISTA, cualquier operación de representación, reproducción, difusión o explotación total o
        parcial de los contenidos y Servicios que ofrece la Aplicación, entre ellos, los programas informáticos, las
        animaciones, los textos, las fotografías, los vídeos, los elementos de diseño gráfico y, de modo más amplio, la
        estructura en general, al margen de los procedimientos, las modalidades, los soportes y los medios empleados
        para ello, pudiendo dar lugar el incumplimiento de dicha prohibición a las correspondientes acciones legales.
      </p>

      <h3>8.3 Licencia</h3>

      <p>
        Los derechos de uso que JUGALISTA le concede al Usuario son solo para uso privado y personal. Se prohíbe al
        Usuario utilizar la Aplicación con otros fines sin la previa autorización expresa por escrito de JUGALISTA.{' '}
      </p>

      <p>
        El Usuario concederá a JUGALISTA una licencia gratuita, no exclusiva, válida en todo el mundo y con carácter
        permanente para la utilización, reproducción, representación, modificación y traducción parcial o completa de
        cualquier elemento sometido a las leyes de la propiedad intelectual y a los derechos de la personalidad
        (incluyendo su derecho a la imagen, su derecho al nombre y a la voz) (textos, fotos, etc.) que pueda llegar a
        facilitar a JUGALISTA a través de la Aplicación.
      </p>

      <p>
        JUGALISTA respetará en todo momento el derecho a la imagen, los atributos de la personalidad, el derecho a la
        intimidad, al igual que la protección de los datos personales del usuario, sin alterar la autenticidad del
        perfil del usuario y ateniéndose a las características de dicho perfil. Esta licencia autorizará a JUGALISTA a
        adaptar esos elementos a fin de que sean compatibles con sus prestaciones técnicas o con los formatos de los
        correspondientes soportes.
      </p>

      <p>El Usuario seguirá siendo titular del contenido y de los datos personales que facilite, </p>

      <p>
        asimismo, eximirá a JUGALISTA de toda responsabilidad ante cualquier acción judicial emprendida, recurso
        interpuesto o condena dictada contra dicha empresa que se derive de la vulneración, por parte del Usuario, de
        los derechos de propiedad intelectual o de los derechos de la personalidad de terceros.{' '}
      </p>

      <h2>9. Fin del contrato </h2>

      <h3>9.1 Desactivación de la Cuenta por parte del Usuario</h3>

      <p>
        El Usuario podrá desactivar su Cuenta en todo momento, sin previo aviso y mediante el envío de la solicitud al
        correo{' '}
        <a href='mailto:hola@jugalista.com' target='_blank' rel='noreferrer'>
          hola@jugalista.com
        </a>
        , en caso de que ya no desee que su perfil esté visible.
      </p>

      <p>Tras la desactivación del perfil:</p>

      <ul>
        <li>
          El perfil del Usuario dejará de estar visible para el resto de los Usuarios en fechas tanto pasadas como
          futuras
        </li>
        <li>
          El cumplimiento de la obligación legal de almacenamiento de los datos no se verá afectado en modo alguno
        </li>
        <li>
          En caso de reactivarse la Cuenta en un plazo máximo de 1 año desde la fecha de cierre de esta, el Usuario
          recuperará la totalidad de los datos conservados y su perfil volverá a estar visible.
        </li>
      </ul>

      <h3>9.2 Desinstalación de la Aplicación por parte del Usuario</h3>

      <p>
        El Usuario podrá desinstalar la Aplicación de su móvil en todo momento sin previo aviso. Al hacerlo, dejará de
        tener acceso a ella, a menos que vuelva a descargarla.
      </p>

      <p>
        La desinstalación de la aplicación no elimina la Cuenta del Usuario. Por ello se recomienda eliminar su Cuenta
        de la Aplicación antes de desinstalarla.
      </p>

      <p>
        La desinstalación de la Aplicación no afectará en modo alguno a la obligación legal de almacenamiento de datos.{' '}
      </p>

      <h3>
        9.3 Suspensión / cancelación de la Cuenta por parte de JUGALISTA en caso de que un Usuario incumpla los Términos
        y Condiciones
      </h3>

      <p>
        En caso de incumplimiento de los Términos y Condiciones por parte del Usuario, JUGALISTA podrá suspender su
        Cuenta en todo momento como medida provisional y proceder a la posterior cancelación de aquella de pleno
        derecho.
      </p>

      <p>
        JUGALISTA podrá suspender la Cuenta del Usuario durante el tiempo necesario para que su Servicio de atención al
        cliente pueda realizar las comprobaciones pertinentes a raíz de la presentación de una denuncia de conformidad
        con lo dispuesto en los presentes Términos y Condiciones.
      </p>

      <p>
        Se invitará al Usuario a ponerse en contacto con el Servicio de atención al cliente de JUGALISTA por correo
        electrónico para obtener más información sobre los motivos de la suspensión de su Cuenta, formular sus posibles
        observaciones al respecto o aportar las pruebas que demuestren que se ha subsanado dicho incumplimiento.
      </p>

      <p>
        Si, transcurrido un plazo máximo de 30 días desde la fecha de notificación de la suspensión de la Cuenta, el
        Usuario no contactase al Servicio de atención al cliente de JUGALISTA, o si las pruebas que hubiera aportado no
        permitieran subsanar tal incumplimiento, JUGALISTA tendrá derecho a notificar al Usuario la suspensión de su
        Cuenta a través de la Aplicación o por correo electrónico y notificarle sobre el período durante el cual se le
        prohibiría volver a registrarse en la Aplicación. Salvo que se especifique lo contrario, la duración de dicho
        período quedará establecida en 1 año a partir de la fecha de notificación de la cancelación de la Cuenta.
      </p>

      <p>Al cancelar la Cuenta, el perfil del Usuario dejará de estar visible para el resto de los Usuarios.</p>

      <h3>9.4 Cancelación de Cuentas inactivas por parte de JUGALISTA</h3>

      <p>JUGALISTA procederá a la cancelación de las Cuentas inactivas, tal como se definen a continuación.</p>

      <p>Se considerará que una Cuenta está inactiva cuando el usuario no use la Aplicación durante 1 año.</p>

      <p>
        El Usuario recibirá un mensaje de correo electrónico en la dirección indicada en la Aplicación informando de la
        cancelación de su cuenta con una antelación de 8 días.
      </p>

      <p>
        Durante este período de preaviso, el Usuario podrá reactivar su Cuenta, lo que suspenderá el proceso de
        cancelación. En tal caso, se preservará todo el contenido del perfil del Usuario.
      </p>
      <p>
        Transcurrido el plazo de preaviso y si el Usuario no se manifestara, JUGALISTA procederá a la eliminación
        automática de la Cuenta.
      </p>
      <p>
        Al eliminar la Cuenta, el perfil del Usuario dejará de estar visible para el resto de los Usuarios. Los datos
        del Usuario se conservarán durante un año desde la fecha de la cancelación, en particular con arreglo las
        obligaciones legales impuestas a JUGALISTA de conformidad con los mecanismos estipulados en la Política de Uso
        de Datos, y los mismos se eliminarán definitivamente transcurrido dicho plazo; El Usuario no podrá volver a
        activar ni solicitar la reactivación de su antigua Cuenta.
      </p>

      <h2>10. Contacto</h2>

      <p>
        Puede ponerse en contacto con JUGALISTA mediante correo electrónico a la dirección{' '}
        <a href='mailto:hola@jugalista.com' target='_blank' rel='noreferrer'>
          hola@jugalista.com
        </a>
      </p>

<h1>Política de Uso de Datos</h1>

<p>
  La Política de Uso de Datos de JUGALISTA está regida por la Ley de Protección de Datos Personales o Ley 1581 de 2012 de Colombia. En caso de contradicción con las disposiciones de JUGALISTA, las leyes nacionales prevalecerán.
</p>

<p>
  Mediante esta Política de Uso de Datos, JUGALISTA desea informarle sobre la manera en la que utiliza sus datos personales. Le invitamos a que la lea atentamente; asimismo, ponemos a su disposición un resumen de los datos que recopilamos y los motivos por los cuales lo hacemos en el siguiente enlace:{' '}
  <Link to={`www.jugalista.com`}>www.jugalista.com</Link>
</p>

<h2>1. Propósito</h2>

<p>
  Para JUGALISTA, la protección de sus datos personales y el respeto de su privacidad es de fundamental importancia. La presente Política de Uso de Datos (al igual que nuestros Términos y Condiciones, así como todo documento al que allí se haga referencia) tiene por objeto informarle de la manera en la que tratamos los datos personales que nos transmite y que recopilamos en el marco del uso que hace de la Aplicación y de los Servicios. Le invitamos a que lea atentamente el presente documento para que comprenda nuestras prácticas en cuanto al tratamiento de sus datos personales y conozca sus derechos con respecto a este tema.
</p>

<p>
  La presente Política de Uso de Datos se aplica a todos los Servicios prestados por JUGALISTA que usted utiliza (a excepción de los servicios prestados y explotados por terceros, en cuyo caso la Política de Uso de Datos de dichos terceros será la que se aplique en lugar de las presentes disposiciones). 
</p>

<h2>2. Información que recopilamos y para qué</h2>

<p>
  JUGALISTA recopila y trata datos personales que le conzciernen cuando utiliza la aplicación de JUGALISTA o cuando se comunica con JUGALISTA. Se trata de información que usted nos transmite directamente o de datos que recopilamos de forma automática.

  Para garantizar la legibilidad de nuestra Política de Uso de Datos, se listan a continuación los datos personales que le conciernen, recopilados y tratados por JUGALISTA.
</p>

<h3>2.1 Datos necesarios para la creación del perfil y la optimización de la experiencia del Usuario y su visibilidad a otros Usuarios</h3>

<p>
  Datos de identificación (Nombres y Apellidos) - Público especial<br/>
  Tipo y número de identificación - para garantizar una Cuenta por persona - Privado especial<br/>
  Dirección de correo electrónico - Privado especial<br/>
  Número de teléfono - Público/Privado especial<br/>
  Sexo - Público<br/>
  Ciudad de residencia - Público<br/>
  Barrio - Público<br/>
  Horario de disponibilidad para jugar - Público<br/>
  Tipo de fútbol - Público<br/>
  Edad - Público - Privado<br/>
  Nacionalidad - Público<br/>
  Peso - Privado<br/>
  Estatura - Privado<br/>
  Talla - Privado<br/>
  Número de camiseta - Público<br/>
  Apodo - Público<br/>
  Pierna dominante - Público<br/>
  Posiciones de juego favoritas - Público<br/>
  Habilidades de juego - Público<br/>
  Años de experiencia y preguntas relativas a desempeño futbolístico - Privado<br/>
</p>

<h3>2.2 Datos recopilados de forma indirecta</h3>

<p>
  Datos relativos a las participaciones en encuentros deportivos - Público<br/>
  Posición geográfica o, en su defecto, su ciudad. - Público<br/>
  Calificaciones realizadas a su Usuario o a los demás - Privado<br/>
  Datos relativos a las denuncias y solicitudes enviadas al servicio de atención al cliente de JUGALISTA - Privado especial<br/>
</p>

<p>
  Los datos anteriormente listados son recopilados por la Aplicación y usados por la misma para proveer a los Usuarios de una mejor experiencia y selección de las personas con las cuales desea reunirse en un encuentro deportivo. Realizamos campañas y operaciones comerciales y publicitarias fuera de la aplicación de JUGALISTA en sitios de terceros, en las redes sociales y en aplicaciones de terceros con los cuales NUNCA se comparte información de identificación del Usuario de tipo público especial o privado especial.
</p>

<p>
  Adicionalmente, JUGALISTA evalúa el rendimiento y uso de la Aplicación en su móvil para mejora y optimización de nuestros servicios y de nuestros conocimientos sobre el uso que hace de ellos:<br/>
  Análisis estadísticos.<br/>
  Análisis del uso de la Aplicación y de los Servicios por parte de los Usuarios.<br/>
  Comprender cómo nuestra Aplicación y sus funciones se utilizan<br/>
  Mejorar la Aplicación, nuestras ofertas y las funciones<br/>
  Comprender sus necesidades<br/>
</p>

<h2>2.1 Datos que nos transmite directamente</h2>

<p>
  Al utilizar nuestros Servicios, nos transmite datos, algunos de los cuales permiten identificarle. Cuando comunica esta información, es posible que una parte de estos datos sean vistos por los demás Usuarios de la Aplicación y se conviertan en públicos en la Aplicación. Para que los pueda identificar con facilidad, cada uno de estos datos se identificará como un dato “Público”. JUGALISTA no asumirá responsabilidad alguna por los datos que un Usuario divulgue y haga públicos en JUGALISTA. No obstante, los demás datos no podrán ser vistos por el resto de los Usuarios, quienes tampoco tendrán acceso a ellos. Cada uno de estos datos se identificará como un dato “Privado” JUGALISTA recopila y procesa datos relativos a su registro y a sus interacciones con JUGALISTA.
</p>

<h3>2.1.1. Datos relativos al registro y al perfil del Usuario</h3>

<p>
  Los datos obligatorios para el registro
  Son los datos básicos necesarios para registrarse a fin de tener acceso a los Servicios. JUGALISTA recupera estos datos de diversas maneras, según la modalidad de registro que haya escogido; sin embargo, para poder crear un perfil completo, se deberá registrar la información solicitada luego de la creación de la cuenta, la cual incluye los datos enunciados en el numeral 2.
</p>

<p>
  Si decide registrarse con su cuenta Google, sus datos podrán ser recuperados para reducir al mínimo las formalidades. Al hacer clic en el botón “Conexión con Google”, acepta que estos últimos nos comuniquen estos datos.
</p>

<p>
  De lo contrario, tiene la posibilidad de registrarse con un correo electrónico, en cuyo caso será usted quien transmita directamente los datos a JUGALISTA. Estos datos están relacionados con su identidad.
</p>

<p>
  Según el modo de registro, algunos datos son obligatorios para la prestación de Servicios. Si no se comunican, JUGALISTA no podrá proporcionar los Servicios disponibles en la Aplicación y usted no podrá crear una Cuenta.
</p>

<p>
  Si bien su biografía es un campo de texto libre, JUGALISTA no recomienda que los Usuarios utilicen la Aplicación para comunicar a los demás Usuarios ni a JUGALISTA aquellos datos que puedan considerarse sensibles. Por datos sensibles se entiende aquellos datos jurídicamente calificados de “sensibles”; en particular, son aquellos datos relativos al origen racial o étnico, a las opiniones políticas, las convicciones religiosas o filosóficas, o la afiliación sindical, así como aquellos relativos a la salud, la vida sexual o la orientación sexual (“Datos sensibles”). Si, a pesar de ello, el Usuario deseara comunicar dichos datos en su perfil, la información comunicada se considerará como “hecha manifiestamente pública” por el Usuario en cuestión.
</p>

<h3>2.1.2. Datos relativos a las interacciones con JUGALISTA:</h3>

<p>
  Al ponerse en contacto con el servicio de asistencia al cliente, informarnos sobre un problema relativo a nuestros Servicios, denunciar a otro usuario, solicitar asistencia, ejercer sus derechos respecto a sus datos personales o participar en promociones, juegos y concursos, encuestas, así como al relatar su experiencia con nuestros Servicios, brindar testimonios y responder a nuestros cuestionarios, JUGALISTA recopilará, según sea el caso, los datos personales que se enumeran a continuación:
</p>

<p>
  Datos relativos a las denuncias y a las solicitudes comunicadas al servicio de atención al cliente de JUGALISTA (privado): fecha y hora de la denuncia o de la solicitud, naturaleza y motivo de la denuncia o de la solicitud, datos relativos al usuario denunciado. Además, una prueba de su identidad podrá ser solicitada en el marco del ejercicio de sus derechos respecto a sus datos personales por razones de seguridad y de privacidad.
</p>

<p>
  JUGALISTA recoge estos datos cuando realiza una solicitud de asistencia a nuestro servicio de atención al cliente, ejerce sus derechos respecto a sus datos personales, nos informa sobre un problema o denuncia a otro usuario. Estos datos son necesarios para tramitar una denuncia o dar respuesta a sus solicitudes. Dichos datos también serán tratados cuando se reciba una denuncia que le concierna. En efecto, JUGALISTA tiene la obligación de recopilar las denuncias de sus Usuarios que pongan en su conocimiento contenidos o conductas que puedan vulnerar nuestros Términos y Condiciones.
</p>
      

<h2>3. ¿Cómo se comparten sus datos?</h2>

<p>
  Solo algunos empleados del Servicio de atención al cliente y los responsables autorizados de JUGALISTA tratarán los datos personales de los Usuarios con los fines anteriormente descritos. Dichos empleados y responsables autorizados sólo tendrán acceso a los datos que necesitan en el marco de sus funciones.
  </p>      

<p>
  También podrán tener acceso y tratar determinados datos personales de los Usuarios por cuenta de JUGALISTA los proveedores de servicios y socios acreditados y fiables, quienes garantizarán la seguridad y la confidencialidad de los datos personales de los Usuarios. Estos proveedores nos ayudan a explotar y mejorar nuestros Servicios, entre ellos:
  <br/><br/>
  Contratistas para nuestra infraestructura y alojamiento de datos<br/>
  Proveedores de software de análisis<br/>
  Proveedores de servicios técnicos para el uso de la Aplicación y/o el correcto funcionamiento de la Aplicación<br/>
  Servicio de atención al cliente para proporcionar la asistencia necesaria a los Usuarios y la gestión de denuncias<br/>
  Socios de las Operaciones comerciales para enviar ofertas, mensajes y contenidos pertinentes a los Usuarios de JUGALISTA<br/>
</p>

<p>
  Asimismo, a petición de los organismos estatales, en especial las autoridades judiciales, y para cumplir con toda solicitud judicial o legal, JUGALISTA podrá transmitir los datos personales de sus Usuarios.

  Por último, puede que transmitamos sus datos en el marco de operaciones empresariales como, entre otras, reestructuraciones, cambios de control, fusiones, adquisiciones, cesiones o disoluciones.
</p>

<h2>4. Datos personales de niños menores de edad</h2>

<p>
  JUGALISTA se niega a recopilar datos sobre los niños menores de edad. En caso de que un Usuario haya mentido sobre su fecha de nacimiento, y especialmente en caso de que haya efectuado una declaración falsa con respecto a su mayoría de edad, se invitará a los padres del menor a que se lo adviertan a JUGALISTA enviando un correo electrónico a una de las direcciones indicadas en, “Contacto”, con el fin solicitar la supresión de los datos. JUGALISTA se comprometerá a cancelar la totalidad de los datos personales del menor a la mayor brevedad posible.
</p>

<h2>5. Contacto</h2>

<p>
  Si tiene alguna duda respecto a la presente Política de Uso de Datos o si desea realizar una solicitud relativa a sus datos personales, puede ponerse en contacto con nosotros en{' '}
  <a href='mailto:hola@jugalista.com' target='_blank' rel='noreferrer'>
    hola@jugalista.com
  </a>
</p>


    </div>
  );
};

export default Legal;
