import { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Context
import { Context } from './Context';

// Components
import Home from './components/Home';
/*import Dashboard from './components/app/Dashboard';
import Login from './components/app/Login';
import CheckAccount from './components/app/CheckAccount';
*/
// Style
import './Index.css';
import Legal from './components/Legal';
/*import Main from './components/app/Main';
import Tournaments from './components/app/tournaments/Tournaments';
import TempTournaments from './components/app/tournaments/TempTournaments';
import Teams from './components/app/teams/Teams';
import MyProfile from './components/app/profile/MyProfile';
import SignUp from './components/app/SignUp';
import Inactive from './components/app/Inactive';
import Recovery from './components/app/Recovery';
import ResetPassword from './components/app/ResetPassword';
import RegisterCompleted from './components/app/RegisterCompleted';
import NewTeam from './components/app/teams/NewTeam';
import ViewTeam from './components/app/teams/ViewTeam';*/

// images to preload
import logo from './assets/logo.png';
import logoSimbolo from './assets/logo-simbolo.png';
import logohorizontal from './assets/logo-horizontal.png';

import Reglamento from './components/Reglamento';
/*import Requests from './components/app/teams/Requests';
import ViewTournament from './components/app/tournaments/ViewTournament';
import TempViewTournament from './components/app/tournaments/TempViewTournament';
import DBFirebase from './components/app/DBFirebase';*/


const App = () => {
  const { data, setData, setLoading } = useContext(Context);

  const preloadImagesArray = [logo, logoSimbolo, logohorizontal];

  useEffect(() => {
    const token = localStorage.getItem('token');
    const token_firebase = localStorage.getItem('token_firebase');
    const user = localStorage.getItem('user');

    if (token && user) {
      data.token_firebase = token_firebase;
      data.user = JSON.parse(user);
      data.token = token;
    }
    cacheImages(preloadImagesArray);
    setData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cacheImages = async (srcArray) => {
    setLoading(true);
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);
    setLoading(false);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/legal' element={<Legal />} />
        {/*<Route
          path='/app'
          element={
            data.loggedIn ? (
              <Dashboard>
                <Main />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/torneos'
          element={
            data.loggedIn ? (
              <Dashboard>
                <Tournaments />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/torneos-activos'
          element={
            data.loggedIn ? (
              <Dashboard>
                <TempTournaments />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/torneos/:id'
          element={
            data.loggedIn ? (
              <Dashboard>
                <ViewTournament />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/torneos-activos/:id'
          element={
            data.loggedIn ? (
              <Dashboard>
                <TempViewTournament />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/equipos'
          element={
            data.loggedIn ? (
              <Dashboard>
                <Teams />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/equipos/:id'
          element={
            data.loggedIn ? (
              <Dashboard>
                <ViewTeam />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/equipos/nuevo'
          element={
            data.loggedIn ? (
              <Dashboard>
                <NewTeam />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/equipos/solicitudes'
          element={
            data.loggedIn ? (
              <Dashboard>
                <Requests />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/perfil'
          element={
            data.loggedIn ? (
              <Dashboard>
                <MyProfile />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app'
          element={
            data.loggedIn ? (
              <Dashboard>
                <Main />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/login'
          element={
            data.loggedIn ? (
              <Dashboard>
                <Main />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path='/app/registro-completo'
          element={
            data.loggedIn ? (
              <Dashboard>
                <RegisterCompleted />
              </Dashboard>
            ) : (
              <Login />
            )
          }
        />
        <Route path='/app/cuenta-inactiva' element={<Inactive />} />
        <Route path='/app/verificar-cuenta/:token' element={<CheckAccount />} />
        <Route path='/app/recuperar-contrasena/' element={<Recovery />} />
        <Route path='/app/reset-password/:token' element={<ResetPassword />} />
        <Route path='/app/registro' element={<SignUp />} />
        <Route path='/firebase' element={<DBFirebase />} 
        <Route path='/torneos' element={<Home />} />/>
        */}
        <Route path='*' element={<Home />} />
      
        <Route path='/reglamento' element={<Reglamento />} />
       
      </Routes>
    </BrowserRouter>
  );
};

export default App;
