import ReglamentoHome from "./ReglamentoHome";
import NavbarHome from "./NavbarHome";
import Mockup from "./Mockup";

const Reglamento = () => {
    return (
        <div className='Reglamento'>
        <NavbarHome />
        <ReglamentoHome />
        <Mockup />
      </div>
    )
}
export default Reglamento;