export const BaseURL =
  process.env.REACT_APP_API_ENV === 'LOCAL'
    ? process.env.REACT_APP_API_URL_LOCAL
    : process.env.REACT_APP_API_URL_REMOTE;

// axios GET request
export const get = (url, params) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);
  }
  const queryParams = new URLSearchParams(params);
  const requestUrl = `${BaseURL + url}?${queryParams.toString()}`;

  return fetch(requestUrl, {
    method: 'GET',
    headers: headers,
  }).then((response) => response.json());
};
// axios POST request
export const post = (url, data) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);
  }
  return fetch(BaseURL + url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  }).then((response) => response.json());
};

// axios PUT request
export const put = (url, data) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);
  }
  return fetch(BaseURL + url, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data),
  }).then((response) => response.json());
};
