import { createContext, useState } from 'react';

// Agent
import { post } from './Agent';

const Context = createContext();

const ContextProvider = ({ children }) => {
  const isFirebaseAuthEnabled = process.env.REACT_APP_AUTH_TYPE === 'firebase' ? true : false;
  const [data, setData] = useState({
    loggedIn: false,
    user: {},
    token: '',
    token_firebase: '',
  });

  const [tournaments, setTournaments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teamRequests, setTeamRequests] = useState([]);

  const logout = () => {
    if (isFirebaseAuthEnabled) {
    }

    localStorage.clear();
    setData({
      loggedIn: false,
      user: {},
      token: '',
    });
    setLoading(false);
    setTeams([]);
    setTournaments([]);
    setTeamRequests([]);
  };

  const checkSession = () => {
    if (isFirebaseAuthEnabled) {
      try {
        const authToken = JSON.parse(localStorage.getItem('token_firebase'));
        const userFirebase = authToken.currentUser;
        if (userFirebase) {
          standardCheckSession();
        } else {
          clearSession();
        }
      } catch (e) {
        clearSession();
      }
    } else {
      standardCheckSession();
    }
  };

  const standardCheckSession = () => {
    setLoading(true);

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    if (token && user) {
      post('check-token').then((response) => {
        if (response?.status === 200) {
          setData({
            loggedIn: true,
            user: JSON.parse(user),
            token: token,
          });
          return true;
        } else {
          setLoading(false);
          clearSession();
        }
      });
    } else {
      clearSession();
      setLoading(false);
    }
  };

  const clearSession = () => {
    if (isFirebaseAuthEnabled) {
    }

    localStorage.clear();
    setData({
      loggedIn: false,
      user: {},
      token: '',
    });
    setLoading(false);
    return false;
  };

  const props = {
    data,
    setData,
    loading,
    setLoading,
    logout,
    checkSession,
    tournaments,
    setTournaments,
    teams,
    setTeams,
    teamRequests,
    setTeamRequests,
  };

  return <Context.Provider value={props}>{children}</Context.Provider>;
};

export { Context, ContextProvider };
