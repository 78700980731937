import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { Link, useNavigate } from 'react-router-dom';

import logo from '../assets/logo-horizontal.png';

const pages = [ /*{
    name: 'Torneos',
    url: '/torneos'
    },*/ {
    name: 'Reglamento',
    url: '/reglamento'
    }];

const  NavbarHome = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const goTo = (url) => {
    navigate(url);
    handleCloseNavMenu();
  }

  return (
    <AppBar position="fixed" style={{backgroundColor:'#2e2f33'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } , justifyContent:'space-between'}}>
            <Box sx={ {display:'flex', justifyContent:'center', alignItems:'center'}} >
                <img src={logo} alt="logo" style={{ width: 100, display: 'block', height:'auto' }} />
            </Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu} >
                  <Link xs={{fontFamily:'Quicksand', fontWeight: 600, fontSize: '1.2rem', textTransform: 'none'}} style={{ textAlign:'center'}} to={page.url}>{page.name}</Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
           
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent:'space-between' }}>
            <Box sx={ {display:'flex', justifyContent:'center', alignItems:'center'}} >
                <img src={logo} alt="logo" style={{ width: 100, display: 'block', height:'auto' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => goTo(page.url)}
                sx={{ my: 2, color: 'white', display: 'block', fontFamily:'Quicksand', fontWeight: 600, fontSize: '1.2rem', textTransform: 'none'}}
              >
                {page.name}
              </Button>
            ))}
            </Box>
        </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavbarHome;
